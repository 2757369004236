import {ScrollToSection} from '../../../../../assets/private/js/scrollToSection';

class Header {
	constructor(element) {
		const logo = element.querySelector( '.js-header__logo' );
		logo.addEventListener( 'click', () => this.handleLogoClick() );
	}

	handleLogoClick() {
		ScrollToSection.scroll( 0 );
	}
}

document.addEventListener(
	'DOMContentLoaded',
	() => {
		new Header( document.querySelector( '.js-header' ) );
	}
);