class SideForm {
    constructor(el) {
        this.el = el
        this.openButton = this.el.querySelector('.js-side-form__open');
        this.externalOpenTriggers = document.querySelectorAll('.js-open-side-form');
        this.closeButtons = this.el.querySelectorAll('.js-side-form__close');
        this.backdrop = this.el.querySelector('.js-side-form__backdrop');
        this.sendTime = 0
        
        
       
        this.openButton.addEventListener('click', (event) => {
            event.preventDefault();
            this.open();
        })

        this.externalOpenTriggers.forEach((trigger) => {
            trigger.addEventListener('click', (event) => {
                event.preventDefault();
                this.open();
            })
        })

        this.closeButtons.forEach((closeButton) => {
            closeButton.addEventListener('click', (event) => {
                event.preventDefault();
                this.close();
            })
        })

        this.backdrop.addEventListener('click', () => {
            // this.close();
        })
    }

    open() {
        this.el.classList.add('is-opened');
    }

    close() {
        this.el.classList.remove('is-opened');
    }
}

document.addEventListener('DOMContentLoaded', () => {
    const el = document.querySelector('.js-side-form');
    if(el) {
        new SideForm(el)

    }
    let currentSetup = document.getElementById('fullscreen').value;
    if (currentSetup == 1) {
        var resizeWithRes = 4500;
    } else {
        var resizeWithRes = 992;
    }
    let resizeWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    if (resizeWidth < resizeWithRes) { 
        document.getElementById('mobileFormBox').appendChild(document.getElementById('migrate'));
        document.getElementById('migrate').classList.remove('is-opened');
    } else {
        document.getElementById('migrate_target').appendChild(document.getElementById('migrate'));
        document.getElementById('migrate').classList.add('is-opened');
        let resizeHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        if (resizeHeight < 1140) {
            document.getElementById('migrate').classList.remove('scrollme'); 
            document.getElementsByClassName('form__footer')[0].classList.add('shadow');
            document.getElementsByClassName('form__content')[0].classList.add('showscroller');
            document.getElementsByClassName('desktop_line')[0].style.display = 'none';

            window.addEventListener("scroll", (event) => {
                let scroll = window.scrollY;
                console.log(scroll);
                if (scroll > 100) {
                    document.getElementById('migrate').style.position = 'fixed';
                    document.getElementById('migrate').style.top = '40px';
                    document.getElementById('migrate').style.height = 'auto';
                    document.getElementById('migrate').style.width = '340px';
                } else {
                    document.getElementById('migrate').removeAttribute('style');
                }
            });
            
        } else {
            document.getElementById('migrate').classList.add('scrollme'); 
            document.getElementsByClassName('form__footer')[0].classList.remove('shadow');
            document.getElementsByClassName('form__content')[0].classList.remove('showscroller');
            document.getElementsByClassName('desktop_line')[0].removeAttribute('style');
        }
     

    }
    window.addEventListener('resize', function(event) {
        let currentSetup = document.getElementById('fullscreen').value;
        if (currentSetup == 1) {
            var resizeWithRes = 4500;
        } else {
            var resizeWithRes = 992;
        }
        resizeWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        let resizeHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
       
        if (resizeWidth < resizeWithRes) {
         //   document.getElementById('mobileFormBox').appendChild(document.getElementById('migrate'));
           document.getElementById('migrate').classList.remove('is-opened'); 
        } else {
            document.getElementById('migrate_target').appendChild(document.getElementById('migrate'));
            document.getElementById('migrate').classList.add('is-opened'); 
        }
        if (resizeHeight < 1140) {
            document.getElementById('migrate').classList.remove('scrollme'); 
            document.getElementsByClassName('form__footer')[0].classList.add('shadow');
            document.getElementsByClassName('form__content')[0].classList.add('showscroller');
            window.addEventListener("scroll", (event) => {
                let scroll = this.scrollY;
                console.log(scroll);
                if (scroll > 100) {
                    document.getElementById('migrate').style.position = 'fixed';
                    document.getElementById('migrate').style.top = '40px';
                    document.getElementById('migrate').style.height = 'auto';
                    document.getElementById('migrate').style.width = '340px';
                } else {
                    document.getElementById('migrate').removeAttribute('style');
                }
            });
            
            
        } else {
            document.getElementById('migrate').classList.add('scrollme'); 
            document.getElementsByClassName('form__footer')[0].classList.remove('shadow');
            document.getElementsByClassName('form__content')[0].classList.remove('showscroller');
         
        }


     }, true);
     

});
