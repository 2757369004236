const Diacritics = require('diacritic');

class SelectMulti {
    constructor(el) {
        this.el = el;
        this.selectOptions = el.querySelectorAll('select option');
        this.input = el.querySelector('.js-select-multi__input');
        this.field = el.querySelector('.js-select-multi__field');
        this.list = el.querySelector('.js-select-multi__list');
        this.expandable = el.querySelector('.js-select-multi__expandable');
        this.itemsEl = el.querySelector('.js-select-multi__items');
        this.items = el.querySelectorAll('.js-select-multi__item');
        this.search = el.querySelector('.js-select-multi__search-input');

        this.updateSelectedText();

        this.items.forEach((item) => {
            Array.from(this.selectOptions)
                .filter(option => option.value === item.dataset.value)
                .map(option => {
                    if (option.selected) {
                        // item.classList.toggle('is-active');
                    }
                });
        });

        this.input.addEventListener('click', () => {
            this.toggle();
        });

        this.search.addEventListener('keyup', e => this.handleSearchChange(e));

        document.addEventListener('click', (event) => {
            if (event.target.closest('.js-select-multi') !== this.el) {
                if (this.close()) {
                    this.dispatchEvent('field-blur');
                }
            }
        });

        this.items.forEach((item) => {
            item.addEventListener('click', (event) => {
                let target = event.target.closest('.js-select-multi__item');
                let current = document.getElementsByClassName('js-select-multi__items')[0].getElementsByClassName('is-active')
                for(var i = 0; i < current.length; i++){
                     console.log(current[i].classList.remove('is-active'));
                }    
                
                target.classList.add('is-active');

                Array.from(this.selectOptions)
                    .filter(option => option.value === target.dataset.value)
                    .map(option => {
                        option.selected = !option.selected;
                        this.dispatchEvent('field-change');
                        this.updateSelectedText();
                    });
            });
        });
    }

    dispatchEvent(name) {
        let event = new CustomEvent(name, {
            detail: {
                value: this.getSelectedOptions(),
            },
        });
        this.el.dispatchEvent(event);
    }

    handleSearchChange(e) {
        const searchValue = e.target.value;
        this.filterFieldsByLabel(searchValue);
    }

    filterFieldsByLabel(value) {
        const formattedValue = this.formatString(value);


        this.items.forEach(item => {
            const label = this.formatString(item.innerText.trim());

            if (label.includes(formattedValue)) {
                item.classList.remove('is-hidden');
            } else {
                item.classList.add('is-hidden');
            }
        });

    }

    formatString(text) {
        text = text.toString().toLowerCase();
        return Diacritics.clean(text.replace(/ /g, ''));
    }

    toggle() {
        if (this.el.classList.contains('is-expanded')) {
            this.close();
        } else {
            this.open();
        }
    }

    open() {
        this.el.classList.add('is-expanded');
        this.expandable.style.maxHeight = this.itemsEl.offsetHeight + 'px';
        if (this.search) {
            setTimeout(() => {
                this.search.focus();
            }, 200);
        }
    }

    close() {
        let closed = false;
        if (this.el.classList.contains('is-expanded')) {
            closed = true;
        }

        this.el.classList.remove('is-expanded');
        this.expandable.style.removeProperty('max-height');

        setTimeout(() => {
            this.search.value = '';
            this.filterFieldsByLabel('');
            this.itemsEl.scrollTop = 0;
        }, 200);

        return closed;
    }

    updateSelectedText() {
        let labels = this.getSelectedOptionLabels();
        if (labels.length) {
            this.input.classList.add('is-active');
        } else {
            this.input.classList.remove('is-active');
        }
        this.field.innerText = labels.join(', ');
    }

    getSelectedOptions() {
        return Array.from(this.selectOptions)
            .filter(option => !!option.selected)
            .map(option => ({value: option.value, label: option.innerText}));
    }

    getSelectedOptionValues() {
        return this.getSelectedOptions()
            .map(option => option.value);
    }

    getSelectedOptionLabels() {
        return this.getSelectedOptions()
            .map(option => option.label);
    }
}

export const selects = {};

document.addEventListener('DOMContentLoaded', () => {
    let elements = document.querySelectorAll('.js-select-multi');
    elements.forEach((el) => {
        selects[el.dataset.fieldName] = new SelectMulti(el);
    });
});

